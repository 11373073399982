import * as React from "react";
import Layout from "../../components/layout"
import Seo from "../../components/seo";
import BreadCrumbs from "../../components/BreadCrumbs";
import PageTitle from "../../components/PageHeader/PageTitle";
import Siegelliste from "../../components/Projekte/Siegelliste";

const Breadcrumbs = [
    {name: 'Ueber mich', href: '#', current: false},
    {name: 'Steckbrief', href: '/UeberMich/Steckbrief', current: true},
]

export default function Steckbrief() {
    return (
        <Layout>
            <Seo title="Kontakt"/>
            <BreadCrumbs pages={Breadcrumbs}/>
            <div className="min-h-full">
                <main className="py-10">
                    <PageTitle HeadlinePart1="Mein" HeadlinePart2="Steckbrief" Subtitle="Mein Profil in aller Kürze"/>

                    <div
                        className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">


                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Dipl.-Inform. Dirk
                                            Illenberger</h3>
                                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Senior Software
                                            Developer</p>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-diit">Ausbildung</dt>
                                                <dd className="mt-1 text-sm text-gray-900"><span className="font-bold">Studium der Informatik (1991 - 1995)</span><br/>Johann
                                                    Wolfgang Goethe Universität, Frankfurt<br/>
                                                    Abschluss: Diplom (Magna Cum Laude)

                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-diit">Sprachen</dt>
                                                <dd className="mt-1 text-sm text-gray-900"><span
                                                    className="font-bold">Deutsch</span> (Muttersprache)<br/><span
                                                    className="font-bold">Englisch</span> (fließend in Wort und Schrift)
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-diit">Programmiersprachen</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <ul>
                                                        <li>C#</li>
                                                        <li>Delphi</li>
                                                        <li>JavaScript</li>
                                                        <li>F#</li>
                                                        <li>PHP</li>
                                                        <li>HTML / CSS</li>
                                                    </ul>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-diit">Frameworks</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <ul>
                                                        <li>ASP.NET, ASP.NET/MVC, .NET Core</li>
                                                        <li>jQuery</li>
                                                        <li>React</li>
                                                        <li>Tailwind</li>
                                                        <li>Bootstrap</li>
                                                    </ul>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-diit">Datenbanken</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <ul>
                                                        <li>SQL Server</li>
                                                        <li>Oracle</li>
                                                        <li>Firebird</li>
                                                        <li>MySql</li>
                                                    </ul>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-diit">Sonstige Anmerkungen</dt>
                                                <dd className="mt-1 text-sm mt-6 prose prose-indigo prose-lg text-gray-500">
                                                    <p>
                                                        Meine langjährige Programmiererfahrung und die Fähigkeit,
                                                        objekt-orientierte
                                                        Designs nach den SOLID-Prinzipien und CleanCode zu erstellen
                                                        sorgen dafür, dass mein Code sehr gut testbar und
                                                        weiterentwickelbar ist. Am liebsten entwickle ich Test-Driven,
                                                        weil sich das als am vorteilhaftesten für meine Auftraggeber
                                                        herausgestellt hat.
                                                    </p><p>
                                                    Zu meinen Kernkompetenzen gehören vor allem der Einsatz von
                                                    Internet-Technologien und die Erstellung intuitiver Front-Ends (hohe
                                                    Nutzerakzeptanz). Des Weiteren habe ich große Erfahrung in der
                                                    Erstellung performanter und robuster Schnittstellen (Data Loader) zu
                                                    Legacy Systemen.
                                                </p><p>
                                                    Ich eigne mich hervorragend als Team Player, weil ich mich
                                                    problemlos in
                                                    Entwicklergruppen auch im internationalen Umfeld einfüge.
                                                </p><p>
                                                    Bei meiner Arbeit lege ich stets Wert darauf, dass ich mich nicht
                                                    mit unwichtigen "Nice-To-Haves" aufhalte, sondern zielgerichtet auf
                                                    ein einsetzbares Endprodukt hinarbeite, das sehr gut testbar ist.
                                                </p><p>
                                                    Obwohl meine Kenntnisse IT-basiert sind, habe ich im Laufe der Jahre
                                                    etliches Finanz-Know-how sammeln können, was sich als sehr nützlich
                                                    für meine Auftraggeber herausgestellt hat.
                                                </p><p>
                                                    Über die Jahre habe ich meine kommunikativen Fähigkeiten durch
                                                    intensiven Kundenumgang weiterentwickelt, so dass meine Auftraggeber
                                                    mich nicht nur zum Erstellen von Software einsetzen können, sondern
                                                    auch für ihren Kundenkontakt.

                                                </p>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Siegelliste />
                                            </div>
                                        </dl>
                                    </div>


                                </div>
                            </section>
                        </div>


                    </div>
                </main>
            </div>
        </Layout>
    )
}